<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="p-6" [class.text-white]="isDark()">
    <p class="mb-4 text-xl">你確定要{{ name() }}嗎？</p>

    <div class="mb-6 leading-normal">
      @for (message of messages(); track message) {
        {{ message }}<br />
      }
    </div>

    @if (data.confirmCode) {
      <div class="flex items-center gap-3">
        <p>{{ confirmMessage() }}</p>
        <div
          class="rounded bg-primary p-2 font-normal tracking-widest text-white"
        >
          {{ checkCode() }}
        </div>
      </div>

      <mat-form-field floatLabel="always" class="fw tight w-full">
        <input matInput formControlName="code" />
        @if (code.errors) {
          <mat-error> 輸入的確認碼不符 </mat-error>
        }
      </mat-form-field>
    } @else {
      <div class="mb-4"></div>
    }

    <div class="flex justify-end gap-4">
      <button
        mat-button
        color="primary"
        [mat-dialog-close]="false"
        type="button"
        class="w-20"
        (click)="cancel()"
      >
        {{ cancelButtonText() }}
      </button>

      <button
        mat-flat-button
        type="submit"
        color="primary"
        class="w-20"
        [ngClass]="{ '!w-32': data.confirmButtonText, '!text-white': isDark() }"
        [disabled]="form.invalid"
      >
        {{ confirmButtonText() }}
      </button>
    </div>
  </div>
</form>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'entries', standalone: true })
export class EntriesPipe implements PipeTransform {
  public transform<T>(
    value: { [key: string]: T } | null | undefined,
  ): { key: string; value: T }[] {
    if (value === undefined || value === null) return [];
    return Object.entries(value).map(([key, value]) => ({ key, value }));
  }
}

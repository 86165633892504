import { Component, Inject } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogClose,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormErrorComponent } from '@ay-gosu/ui/common/form-error';

@Component({
  selector: 'gosu-rename',
  templateUrl: './rename.dialog.html',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormErrorComponent,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatDialogClose,
  ],
})
export class RenameDialog {
  public oriName = '';

  public name = new FormControl('', Validators.required);

  public form = new FormGroup({ name: this.name });

  public constructor(
    private _matDialogRef: MatDialogRef<RenameDialog>,
    @Inject(MAT_DIALOG_DATA)
    public data: { name: string; maxLength?: number },
  ) {
    this.oriName = data.name;
    this.name.setValue(data.name);

    if (data.maxLength) {
      this.name.setValidators([
        Validators.required,
        Validators.maxLength(data.maxLength),
      ]);
    }
  }

  public onSubmit() {
    this._matDialogRef.close(this.name.value);
  }
}

<div class="flex flex-row gap-2">
  @for (button of buttons(); track $index) {
    @switch (button.type ?? 'flat') {
      @case ('normal') {
        <button
          mat-button
          [color]="button.color"
          (click)="button.click(dialogRef()!)"
        >
          <ng-container *ngTemplateOutlet="buttonTemplate; context: { button }">
          </ng-container>
        </button>
      }

      @case ('flat') {
        <button
          mat-flat-button
          [color]="button.color"
          (click)="button.click(dialogRef()!)"
        >
          <ng-container *ngTemplateOutlet="buttonTemplate; context: { button }">
          </ng-container>
        </button>
      }

      @case ('raised') {
        <button
          mat-raised-button
          [color]="button.color"
          (click)="button.click(dialogRef()!)"
        >
          <ng-container *ngTemplateOutlet="buttonTemplate; context: { button }">
          </ng-container>
        </button>
      }

      @case ('stroked') {
        <button
          mat-stroked-button
          [color]="button.color"
          (click)="button.click(dialogRef()!)"
        >
          <ng-container *ngTemplateOutlet="buttonTemplate; context: { button }">
          </ng-container>
        </button>
      }
    }
  }
</div>

<ng-template #buttonTemplate let-button="button">
  <div class="leading-8">
    @if (button.leftIcon) {
      <mat-icon class="mat-18 align-middle">{{ button.leftIcon }}</mat-icon>
    }
    {{ button.text }}
    @if (button.rightIcon) {
      <mat-icon class="mat-18 align-middle">{{ button.rightIcon }}</mat-icon>
    }
  </div>
</ng-template>

import { Component, Inject, signal } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogClose,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'gosu-prompt',
  templateUrl: './prompt.dialog.html',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatDialogClose,
  ],
})
export class PromptDialog {
  public title = signal('');

  public text = new FormControl('', Validators.required);

  public form = new FormGroup({ text: this.text });

  public constructor(
    private _matDialogRef: MatDialogRef<PromptDialog>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string; text: string },
  ) {
    this.title.set(data.title);
    this.text.setValue(data.text ?? '');
  }

  public onSubmit() {
    this._matDialogRef.close(this.text.value);
  }
}

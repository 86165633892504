import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogClose } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'gosu-select',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatSelectModule,
    MatDialogClose,
    FormsModule,
    MatDialogClose,
  ],
  template: `
    <div class="w-[420px] gap-2 p-6">
      <div>
        {{ title }}
      </div>

      <mat-select [(ngModel)]="current" class="size-12">
        <mat-option
          *ngFor="let option of options"
          [disabled]="option.disabled"
          [value]="option.value"
        >
          {{ option.label }}
        </mat-option>
      </mat-select>

      <div class="mt-4 flex flex-row justify-end gap-2">
        <button
          mat-button
          [mat-dialog-close]="false"
          type="button"
          class="w-14"
        >
          取消
        </button>
        <button
          mat-flat-button
          color="primary"
          class="w-14"
          [mat-dialog-close]="current"
        >
          確定
        </button>
      </div>
    </div>
  `,
})
export class SelectDialog {
  public title!: string;

  public current!: string;

  public options: { label: string; value: string; disabled: boolean }[] = [];

  public constructor(
    @Inject(MAT_DIALOG_DATA)
    data: {
      title: string;
      current: string;
      options: { label: string; value: string; disabled: boolean }[];
    },
  ) {
    this.title = data.title;
    this.current = data.current;
    this.options = data.options;
  }
}

import { CommonModule } from '@angular/common';
import {
  AfterContentInit,
  Component,
  Inject,
  Injector,
  Optional,
  booleanAttribute,
  effect,
  input,
  signal,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { Button, ButtonsComponent } from '../buttons';
import { convertToArray } from '../convert-to-array';

/**
 * 成功訊息 Dialog，可以直接顯示在 HTML 中，也可以透過 `DialogService` 來使用
 * 如果直接使用時，在內容中可以加入按鈕
 * <gosu-success
 *   title="密碼變更成功"
 *   message="請重新登入，踏上機器人冒險之旅吧！"
 * >
 *    <button mat-flat-button color="primary" routerLink="/backstage/login/form">
 *      前往登入
 *    </button>
 * </gosu-success>
 *
 * 如果使用 `DialogService` 來使用，則透過 config 來設定按鈕
 * this.dialogService.open(SuccessDialog, {
 *   title: '密碼變更成功',
 *   messages: '請重新登入，踏上機器人冒險之旅吧！',
 *   buttons: [{
 *     type: 'flat',
 *     color: 'primary',
 *     text: '前往登入',
 *     click: () => this.router.navigate(['/login/form']),
 *   }]
 * })
 */
@Component({
  selector: 'gosu-success',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule, ButtonsComponent],
  templateUrl: './success.component.html',
})
export class SuccessDialog implements AfterContentInit {
  public iconInput = input('done', { alias: 'icon' });

  public icon = signal('done');

  public iconOutlinedInput = input(false, {
    alias: 'iconOutlined',
    transform: booleanAttribute,
  });

  public iconOutlined = signal(false);

  public titleInput = input.required<string>({ alias: 'title' });

  public title = signal('');

  public messageInput = input<string[], string | string[]>([], {
    alias: 'message',
    transform: convertToArray,
  });

  public messages = signal<string[]>([]);

  public isDialog = signal(false);

  // 只有在 dialog 模式下顯示，否則顯示 ng-content
  public buttons = signal<Button[]>([]);

  public constructor(
    @Optional()
    public readonly dialogRef: MatDialogRef<any>,

    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public readonly _data: {
      icon: string;
      iconOutlined: boolean;
      title: string;
      messages: string | string[];
      buttons: Button[];
    },
    public readonly _injector: Injector,
  ) {}

  public ngAfterContentInit() {
    const data = this._data;
    if (this.dialogRef) {
      this.isDialog.set(true);

      this.icon.set(data?.icon ?? 'done');
      this.iconOutlined.set(data?.iconOutlined ?? false);
      this.title.set(data?.title);
      this.messages.set(data?.messages ? convertToArray(data.messages) : []);

      const defaultButton = {
        type: 'flat',
        color: 'primary',
        text: '確定',
        click: () => this.dialogRef.close(),
      };

      this.buttons.set(data?.buttons ?? [defaultButton]);
    } else {
      const injector = this._injector;
      const option = { injector, allowSignalWrites: true };
      effect(() => this.icon.set(this.iconInput()), option);
      effect(() => this.iconOutlined.set(this.iconOutlinedInput()), option);
      effect(() => this.title.set(this.titleInput()), option);
      effect(() => this.messages.set(this.messageInput()), option);
    }
  }
}

import { ConnectedPosition } from '@angular/cdk/overlay';
import { ElementRef } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';

export class MatConnectedDialogConfig<D = any> extends MatDialogConfig<D> {
  public elementRef?: ElementRef;

  public overlay?: boolean = false;

  public positions?: ConnectedPosition[];

  public offsetX?: number = 8;
  public offsetY?: number = 8;
}

import { DialogRef } from '@angular/cdk/dialog';
import { GlobalPositionStrategy, PositionStrategy } from '@angular/cdk/overlay';
import {
  DialogPosition,
  MatDialogConfig,
  MatDialogContainer,
  MatDialogRef,
} from '@angular/material/dialog';

/* eslint-disable */

export class MatConnectedDialogRef<T, R = any> extends MatDialogRef<T, R> {
  public constructor(
    private readonly __ref: DialogRef<R, T>,
    config: MatDialogConfig,
    _containerInstance: MatDialogContainer,
  ) {
    super(__ref, config, _containerInstance);
  }

  /**
   * Updates the dialog's position.
   * @param position New dialog position.
   */
  override updatePosition(position?: DialogPosition): this {
    const strategy = this.__ref.config.positionStrategy as PositionStrategy;

    if (strategy instanceof GlobalPositionStrategy) {
      if (position && (position.left || position.right)) {
        position.left
          ? strategy.left(position.left)
          : strategy.right(position.right);
      } else {
        strategy.centerHorizontally();
      }

      if (position && (position.top || position.bottom)) {
        position.top
          ? strategy.top(position.top)
          : strategy.bottom(position.bottom);
      } else {
        strategy.centerVertically();
      }
    }

    return this;
  }
}

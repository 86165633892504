<div
  class="min-w-[250px] max-w-[460px] flex-col rounded bg-white p-6 text-center text-gray-700"
>
  <div class="mb-4 inline-block h-20 w-20 rounded-full bg-red-400 p-4">
    <mat-icon
      class="mat-18 !h-12 !w-12 text-5xl text-white"
      [ngClass]="{ 'material-icons-outlined': iconOutlined }"
    >
      {{ icon() }}
    </mat-icon>
  </div>

  <div class="mb-3 text-lg font-medium">
    {{ title() }}
  </div>

  <div class="mb-6 leading-normal">
    @for (message of messages(); track message) {
      {{ message }}<br />
    }
  </div>

  <div class="flex flex-col items-center gap-6">
    @if (isDialog()) {
      <gosu-buttons
        [buttons]="buttons()"
        [dialogRef]="dialogRef"
      ></gosu-buttons>
    } @else {
      <ng-content></ng-content>
    }
  </div>
</div>

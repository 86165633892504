import { AbstractControl, ValidatorFn } from '@angular/forms';

export function comparisonString(compared: string): ValidatorFn {
  return function (control: AbstractControl) {
    const value: string = control.value.toUpperCase();
    compared = compared.toUpperCase();

    if (value !== compared) {
      return { 'comparison-string': true };
    }

    return null;
  };
}

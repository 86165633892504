import { CommonModule } from '@angular/common';
import { Component, Inject, computed, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogClose } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';

@Component({
  selector: 'gosu-failure-table',
  standalone: true,
  imports: [
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    MatDialogClose,
    CommonModule,
  ],
  templateUrl: './failure-table.component.html',
})
export class FailureTableComponent<T = any> {
  public title = signal(this.data.title);

  public list = signal(this.data.list);

  public columns = signal<{ label: string; field: string }[]>([]);

  public displayedColumns = computed(() => this.columns().map((c) => c.field));

  public hiddenHeader = signal(false);

  public constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      list: T[];
      columns: ({ label: string; field: keyof T } | keyof T)[];
    },
  ) {
    const hiddenHeader = typeof data.columns[0] === 'string';
    this.hiddenHeader.set(hiddenHeader);

    const columns = data.columns.map((column) =>
      typeof column === 'string' ? { label: column, field: column } : column,
    ) as { label: string; field: string }[];

    this.columns.set(columns);
  }
}

<div class="mx-auto my-8" [ngClass]="{ 'w-80': isDialog }">
  <div class="flex h-full flex-col justify-center">
    <mat-progress-spinner
      color="primary"
      class="mx-auto my-8"
      [mode]="mode()"
      [value]="value()"
    >
    </mat-progress-spinner>

    <div class="text-center align-top leading-10">
      @if (isDialog()) {
        {{ title() }}
      } @else {
        <ng-content></ng-content>
      }
    </div>
  </div>
</div>

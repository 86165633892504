import { Pipe } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'nl2br',
  pure: true,
  standalone: true,
})
export class Nl2br {
  public constructor(private readonly _domSanitizer: DomSanitizer) {}

  public transform(value: string): any {
    return this._domSanitizer.bypassSecurityTrustHtml(
      value.replace(/\n/g, '<br />'),
    );
  }
}

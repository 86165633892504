<form [formGroup]="form" (ngSubmit)="onSubmit()" class="p-6">
  <div class="min-w-[240px] max-w-[500px] p-1.5">
    <p class="mb-4">
      重新命名 <span class="text-primary">{{ oriName }}</span>
    </p>

    <mat-form-field class="fw tight w-full">
      <input matInput formControlName="name" />
      <mat-error>
        <gosu-form-error [control]="name"></gosu-form-error>
      </mat-error>
    </mat-form-field>

    <div class="flex justify-end gap-4">
      <button
        mat-flat-button
        [mat-dialog-close]="false"
        type="button"
        class="w-20"
      >
        取消
      </button>
      <button
        mat-flat-button
        type="submit"
        color="primary"
        class="w-20"
        [disabled]="form.invalid"
      >
        確定
      </button>
    </div>
  </div>
</form>

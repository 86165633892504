import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogClose,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { Nl2br } from '@ay-gosu/ui/common/nl2br';
import { Button, ButtonsComponent } from '../buttons';

export const CANCEL_BUTTON = {
  type: 'normal',
  text: '取消',
  click: (dialogRef) => dialogRef.close(false),
} as Button;

export const OK_BUTTON = {
  type: 'flat',
  color: 'primary',
  text: '確定',
  click: (dialogRef) => dialogRef.close(true),
} as Button;

@Component({
  selector: 'gosu-confirm',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatSelectModule,
    MatDialogClose,
    FormsModule,
    MatDialogClose,
    A11yModule,
    Nl2br,
    ButtonsComponent,
  ],
  template: `
    <div class="max-w-[470px] gap-2 p-6">
      <div class="mb-3 text-lg font-medium">
        {{ title }}
      </div>

      <div [innerHTML]="description | nl2br"></div>

      <div class="mt-7 flex flex-row justify-end gap-2">
        <gosu-buttons
          [buttons]="buttons"
          [dialogRef]="dialogRef"
        ></gosu-buttons>
      </div>
    </div>
  `,
})
export class ConfirmDialog<T = boolean> {
  public title!: string;

  public description!: string;

  public buttons: Button<T>[] = [];

  public constructor(
    @Inject(MAT_DIALOG_DATA)
    data: {
      title: string;
      description: string;
      buttons: Button<T>[];
    },
    public readonly dialogRef: MatDialogRef<T>,
  ) {
    this.title = data.title;
    this.description = data.description;

    this.buttons = data.buttons ?? [CANCEL_BUTTON, OK_BUTTON];
  }
}

import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { Button } from './button.type';

@Component({
  selector: 'gosu-buttons',
  standalone: true,
  imports: [MatButtonModule, MatIconModule, CommonModule],
  templateUrl: './buttons.component.html',
})
export class ButtonsComponent {
  public buttons = input<Button[]>([]);

  public dialogRef = input<MatDialogRef<any> | null>(null);
}

<div class="relative min-w-[480px]">
  <button mat-icon-button mat-dialog-close class="!absolute right-2 top-2">
    <mat-icon>close</mat-icon>
  </button>

  <div class="p-6">
    <div class="leading-10">
      {{ title() }}
    </div>

    <table mat-table aria-label="失敗資料" [dataSource]="list()">
      @for (column of columns(); track $index) {
        <ng-container [matColumnDef]="column.field">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="!text-base !leading-[52px]"
          >
            {{ column?.label }}
          </th>
          <td mat-cell *matCellDef="let row" class="!text-base !leading-[52px]">
            {{ row[column.field] }}
          </td>
        </ng-container>
      }

      @if (!hiddenHeader()) {
        <tr mat-header-row *matHeaderRowDef="displayedColumns()"></tr>
      }
      <tr mat-row *matRowDef="let row; columns: displayedColumns()"></tr>
    </table>
  </div>
</div>
